import React, { useEffect } from "react"
// import "../../static/pixi"
// import * as peach from "../../static/bundle.js"
import queryString from "query-string"

const PeachPage = props => {
  const { story_no } = queryString.parse(props.location.search)

  useEffect(() => {
    let peach
    ;(async () => {
      peach = await import("../../static/bundle.js")
      ChannelIO("shutdown")
      const isHidden = props.location?.state?.isHidden || false
      peach.client(story_no, isHidden)
    })()

    return () => {
      ChannelIO("boot", {
        pluginKey: process.env.CHANNELKEY,
        zIndex: 98,
      })
      peach?.close()
    }
  }, [story_no])

  return (
    <style type="text/css">{"@media print{@page {size: landscape}}"}</style>
  )
}

export default PeachPage

// let sampleclose = () => {
//   navigate(-1)
// }
// peach.setclose(sampleclose)
